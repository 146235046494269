/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import { H2, Button } from '@allthingswww/client-act-shared';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { getNewMembers } from '../../../redux/members';
import memberBuilder from './muiColumnBuilder';

export default () => {
  const members = useSelector(getNewMembers);

  const muiDataTableBuilder = memberBuilder()
    .setData(members)
    .getColumnItemByKey('fullName')
    .show().moveToEnd().build()

    .getColumnItemByKey('companyName')
    .show().moveToEnd().build()

    .getColumnItemByKey('phone')
    .show().moveToEnd().build()

    .getColumnItemByKey('email')
    .show().moveToEnd().build()

    .getColumnItemByKey('adminReviewed')
    .show().moveToEnd().build()

    .sortVisible()
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'single',
    });

  const customToolBarRenderer = (selectedRows) => {
    const selectedItem = muiDataTableBuilder.findItemsBySelectionData(selectedRows)[0];

    return (
      <div>
        <Button.Primary newWindow href={`/member/${selectedItem._id}`}>View</Button.Primary>
      </div>
    );
  };

  muiDataTableBuilder.applyOptions({ customToolbarSelect: customToolBarRenderer });

  const data = members;
  return (
    <div>
      <H2>
        In Progress Applications
      </H2>

      <MUIDataTable
        title="Applications still in progress"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}

      />
    </div>
  );
};
