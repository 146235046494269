/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import { H2 } from '@allthingswww/client-act-shared';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  getEventsItems,
  archiveEventItem,
  getIsDeletingEventItem,
} from '../../../redux/eventsItems';
import eventItemsBuilder from './Builders/eventItemsTableBuilder';

export default ({ children }) => {
  const eventItems = useSelector(getEventsItems);
  const isDeletingEventItem = useSelector(getIsDeletingEventItem);
  const dispatch = useDispatch();

  const muiDataTableBuilder = eventItemsBuilder()
    .setData(eventItems)
    .getColumnItemByKey('name')
    .show().moveToEnd().build()

    .getColumnItemByKey('description')
    .show().moveToEnd().build()

    .getColumnItemByKey('minQty')
    .show().moveToEnd().build()

    .getColumnItemByKey('maxQty')
    .show().moveToEnd().build()

    .getColumnItemByKey('unitPrice')
    .show().moveToEnd().build()

    .sortVisible()
    .showDownload(true)
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'multiple',
      onRowsDelete: (rowsToArchive) => deleteEventItems(rowsToArchive),
    });

  const data = eventItems;

  const deleteEventItems = (rowsToArchive) => {
    const eventItemsToArchive = rowsToArchive.data
        && rowsToArchive.data.map((d) => data[d.dataIndex]);
    eventItemsToArchive
      && eventItemsToArchive.forEach((eventItem) => {
        const id = eventItem._id;
        dispatch(archiveEventItem({ id }));
      });
  };

  if (isDeletingEventItem) {
    return <h2>Loading...</h2>;
  }

  return (
    <div>
      <H2>
        Event Items
      </H2>
      {children}
      <MUIDataTable
        title="Event Items"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}

      />
    </div>
  );
};
