import { ColumnBuilder, ColumnItem } from '../muiColumnBuilder';

export default () => ColumnBuilder([
  ColumnItem('_id', 'Purchase ID').filter(true),
  ColumnItem('eventName', 'Event Name'),
  ColumnItem('transactionAmount', 'Transaction Amount'),
  ColumnItem('transactionId', 'Transaction Id'),
  ColumnItem('transactionStatus', 'Transaction Status'),
  ColumnItem('transactionTimestamp', 'Transaction Timestamp'),

])
  .showPrint(false);
