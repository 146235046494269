/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import styled from 'styled-components';
import { H2, Button } from '@allthingswww/client-act-shared';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { navigate } from 'gatsby';
import { getMemberPurchases } from '../../../redux/member';
import myPurchasesBuilder from './Builders/myPurchasesTableBuilder';

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const formatPurchaseData = (memberPucharches = []) => {
  const finalMemberPucharches = memberPucharches.map(({
    _id, transaction, itemIds, event,
  }) => {
    const tempObj = {};
    tempObj._id = _id;
    tempObj.transactionId = transaction._id;
    tempObj.transactionStatus = transaction.success ? 'success' : 'failed';
    tempObj.transactionAmount = transaction.amount;
    tempObj.transactionTimestamp = transaction.dateTime;
    tempObj.eventItemsBought = itemIds.data.map((itemId) => ({
      name: itemId.name,
      _id: itemId._id,
    }));
    tempObj.eventId = event._id;
    tempObj.eventName = event.name;
    return tempObj;
  });
  return finalMemberPucharches;
};

export default ({ children }) => {
  const memberPurchases = useSelector(getMemberPurchases);
  const formattedPurchaseData = formatPurchaseData(memberPurchases);

  const redirectToPrint = (data) => navigate('/print-purchase', { state: data });

  const muiDataTableBuilder = myPurchasesBuilder()
    .setData(formattedPurchaseData)
    .getColumnItemByKey('_id')
    .show().moveToEnd().build()

    .getColumnItemByKey('eventName')
    .show().moveToEnd().build()

    .getColumnItemByKey('transactionAmount')
    .show().moveToEnd().build()

    .getColumnItemByKey('transactionId')
    .show().moveToEnd().build()

    .getColumnItemByKey('transactionStatus')
    .show().moveToEnd().build()

    .getColumnItemByKey('transactionTimestamp')
    .show().moveToEnd().build()

    .sortVisible()
    .showDownload(true)
    .applyOptions({
      sort: true,
      filterType: 'checkbox',
      selectableRows: 'multiple',
      customToolbarSelect: () => {},
    });

  const customToolBarRenderer = (selectedRows) => {
    const selectedItems = muiDataTableBuilder.findItemsBySelectionData(
      selectedRows,
    );

    return (
      <ActionButtons>
        {selectedItems.length <= 1 && (
        <Button.Primary href="" onClick={() => redirectToPrint(selectedItems[0])}>
          Print
        </Button.Primary>
        )}
      </ActionButtons>
    );
  };

  muiDataTableBuilder.applyOptions({
    customToolbarSelect: customToolBarRenderer,
  });

  const data = formattedPurchaseData;
  return (
    <div>
      <H2>
        My Purchases
      </H2>
      {children}
      <MUIDataTable
        title="My Purchases"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}

      />
    </div>
  );
};
