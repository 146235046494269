/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import styled from 'styled-components';
import { H2, Button } from '@allthingswww/client-act-shared';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { getEvents, updateEvent } from '../../../redux/event';
import eventBuilder from './Builders/eventTableBuilder';

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-around;
`;

export default ({ children }) => {
  const events = useSelector(getEvents);
  const dispatch = useDispatch();

  const handleChangePublicEventStatus = (id, eventPublicStatus) => {
    dispatch(updateEvent({ data: { isPublic: !eventPublicStatus }, id }));
  }
  const handlePublish = (id, eventPublishedStatus) => {
    dispatch(updateEvent({ data: { isPublished: !eventPublishedStatus }, id }));
  };

  const muiDataTableBuilder = eventBuilder()
    .setData(events)
    .getColumnItemByKey('name')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('venueZip')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('venueCity')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('venueName')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('venuePhone')
    .show()
    .moveToEnd()
    .build()

    .sortVisible()
    .showDownload(true)
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'multiple',
      customToolbarSelect: () => {},
    });

  const customToolBarRenderer = (selectedRows) => {
    const selectedItems = muiDataTableBuilder.findItemsBySelectionData(
      selectedRows,
    );

    return (
      <ActionButtons>
        {selectedItems.length <= 1 && (
          <>
            <Button.Primary href={`/event-edit/${selectedItems[0]._id}`}>
              Edit
            </Button.Primary>
            <Button.Primary href="" handleClick={() => handleChangePublicEventStatus(selectedItems[0]._id, selectedItems[0].isPublic)}>
              {!selectedItems[0].isPublic ? 'Make Public' : 'Make Private'}
            </Button.Primary>
            <Button.Primary href="" handleClick={() => handlePublish(selectedItems[0]._id, selectedItems[0].isPublished)}>
              {!selectedItems[0].isPublished ? 'Publish' : 'Unpublish'}
            </Button.Primary>
            <Button.Button variant href={`/event-purchase-details/${selectedItems[0]._id}`}>
              View Purchases
            </Button.Button>
          </>
        )}
      </ActionButtons>
    );
  };

  muiDataTableBuilder.applyOptions({
    customToolbarSelect: customToolBarRenderer,
  });

  const data = events;
  return (
    <div>
      <H2>Events</H2>
      {children}
      <MUIDataTable
        title="Events"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}
      />
    </div>
  );
};
