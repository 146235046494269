/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from '@reach/router';

import { memberLevelCheckBuilder } from '../../../lib';

import {
  getMyInfo,
  isGuest,
  isPending,
  isMember as isMemberSelector,
  isAdmin,
  isSuperAdmin,
  isReviewed,
  getIsActive,
} from '../../../redux/member';

const StyledLink = styled(Link)`
  color: #0f0f3a;
  &:hover {
    border-bottom: 2px solid #777;
  }
`;
const NavContainer = styled.div`
  padding-bottom: 15px;
  min-width: 12rem;
`;

const StyledH2 = styled.h2`
  font-size: 24px;
  margin: 0.5rem 0 1rem;
  color: #0f0f3a;
  font-family: piazzolla;
  font-weight: 600;
`;

export const NavItem = ({ to, children }) => (
  <NavContainer>
    <StyledLink to={to}>{children}</StyledLink>
  </NavContainer>
);

const MemberNav = () => {
  const member = useSelector(getMyInfo);
  const guestUser = useSelector(isGuest);
  const pendingUser = useSelector(isPending);
  const isReviewedUser = useSelector(isReviewed);
  const memberUser = useSelector(isMemberSelector);
  const adminUser = useSelector(isAdmin);
  const superAdminUser = useSelector(isSuperAdmin);
  const isActive = useSelector(getIsActive);

  const { membershipLevel } = member || {};

  const { isNew, isInitial, isMember } = memberLevelCheckBuilder(
    membershipLevel,
  );

  // return (
  //   <div>
  //     {
  //     (() => {
  //       if (guestUser) {
  //         return <StyledH2>New Membership</StyledH2>;
  //       }
  //       if ((guestUser || pendingUser) && isNew()) {
  //         return <NavItem to="/members">Application</NavItem>;
  //       }
  //       if (!isReviewedUser) {
  //         return <NavItem to="/members/pending">Membership Status</NavItem>;
  //       }
  //       if ((pendingUser || !isReviewedUser) && isInitial()) {
  //         return <NavItem to="/members/pending">Membership Status</NavItem>;
  //       }
  //       if (memberUser || adminUser) {
  //         return (
  //           <>
  //             <StyledH2>Members</StyledH2>
  //             <NavItem to="/members/">Home</NavItem>
  //             <NavItem to="/members/my-info">My Info</NavItem>
  //             <NavItem to="members/messages">Send Message</NavItem>
  //           </>
  //         );
  //       }
  //     })()
  //    }
  //   </div>
  // );

  return (
    <div>
      {/* New Member */}
      {guestUser && <StyledH2>New Membership</StyledH2>}
      {(guestUser || pendingUser || !isReviewedUser) && isNew() && (
        <NavItem to="/members">Application</NavItem>
      )}
      {(pendingUser || !isReviewedUser) && <></>}
      {(pendingUser || !isReviewedUser) && isInitial() && (
        <NavItem to="/members/pending">Membership Status</NavItem>
      )}

      {!isReviewedUser && <NavItem to="/members/pending">Membership Status</NavItem>}

      {/* Member */}
      {(memberUser || adminUser) && <StyledH2>Members</StyledH2>}
      {(memberUser || adminUser) && <NavItem to="/members/">Home</NavItem>}
      {(memberUser || adminUser) && (
        <NavItem to="/members/my-info">My Info</NavItem>
      )}
      {memberUser && isMember() && isReviewedUser && (
        <NavItem to="/members/business">Business Profile</NavItem>
      )}

      {memberUser && isMember() && isReviewedUser && (
        <NavItem to="/members/my-purchases">My purchases</NavItem>
      )}
      {memberUser && isMember() && isReviewedUser && (
        <NavItem to="members/images">Images</NavItem>
      )}
      {(memberUser || adminUser) && (
        <NavItem to="members/messages">Send Message</NavItem>
      )}
      {(!isActive && memberUser) && (
        <NavItem to="/members/choose-membership-plan">Choose membership plan</NavItem>
      )}
      {(isActive && memberUser) && (
        <NavItem to="/members/manage-subscriptions">Manage Subscriptions</NavItem>
      )}

      {/* Admin */}
      {adminUser && <StyledH2>Administration</StyledH2>}
      {superAdminUser && (
        <NavItem to="members/approveApplication">New Applications</NavItem>
      )}
      {adminUser && (
        <NavItem to="members/approvePending">Pending Approvals</NavItem>
      )}
      {adminUser && <NavItem to="members/professions">Professions</NavItem>}
      {adminUser && <NavItem to="members/members">Members</NavItem>}
      {adminUser && <NavItem to="members/archivedMembers">Archived Members</NavItem>}
      {adminUser && <NavItem to="members/events">View all Events</NavItem>}
      {adminUser && <NavItem to="members/createEvent">Create Event</NavItem>}
      {adminUser && <NavItem to="members/createEventItem">Create Event Items</NavItem>}
      {adminUser && <NavItem to="members/eventItems">View all Events Items</NavItem>}
      {adminUser && <NavItem to="/members/my-purchases">My Purchases</NavItem>}
      {/*
      {adminUser && <NavItem to="members/siteAdmin">Site Admin</NavItem>}
      {superAdminUser && (
        <NavItem to="/members/superAdmin">Super Admin</NavItem>
      )} */}
    </div>
  );
};

export default MemberNav;
