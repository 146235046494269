import React, { useRef } from 'react';
import styled from 'styled-components';
import ContextMenu from './ContextMenu';

const StyledContainer = styled.div`
    display: flex;
     /* justify-content: center; */
    align-items: flex-start; 
    flex-wrap: wrap;
    flex-direction: row;
`;

const Container = ({ children, menuItems, myItem }) => {
  const containerRef = useRef(null);
  return (
    <StyledContainer ref={containerRef}>
      {children}

      <ContextMenu
        parentRef={containerRef}
        items={menuItems}
        myItem={myItem}
      />
    </StyledContainer>
  );
};

export default Container;
