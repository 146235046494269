/* eslint-disable no-restricted-syntax */
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { H2 } from '@allthingswww/client-act-shared';

import Survey from '../../Survey';
import surveyData from '../../../../static/surveys/createEventItem.json';
import { createEventItem } from '../../../redux/eventsItems';

const Container = styled.div``;

const CENTS = 100;

export default () => {
  const dispatch = useDispatch();

  const onSubmit = async (surveyResults) => {
    const { data } = surveyResults;

    const payload = {
      ...data,
      unitPrice: data.unitPrice * CENTS,
    };

    dispatch(createEventItem(payload));
  };

  return (
    <Container>
      <H2>Create an event item</H2>
      <Survey
        model={surveyData}
        onSubmit={onSubmit}
        data={{}}
      />
    </Container>
  );
};
