import { ColumnBuilder, ColumnItem } from '../muiColumnBuilder';

export default () => ColumnBuilder([
  ColumnItem('_id', 'ID').filter(true),
  ColumnItem('name', 'Name').filter(true),
  ColumnItem('venueZip', 'Venue Zip'),
  ColumnItem('memberFee', 'Member Fee'),
  ColumnItem('nonMemberFee', 'Non Act Member'),
  ColumnItem('venueCity', 'Venue City'),
  ColumnItem('venueName', 'Venue Name'),
  ColumnItem('venuePhone', 'Venue Phone'),
  ColumnItem('venueEmail', 'Venue Email'),
  ColumnItem('venueWebsite', 'Venue Website'),
  ColumnItem('venueAddress1', 'Venue Address 1'),
  ColumnItem('venueAddress2', 'Venue Address 2'),
  ColumnItem('viewCount', 'Venue View Count'),
  ColumnItem('photoUrls', 'Photo Urls'),
  ColumnItem('date', 'Date'),
  ColumnItem('endDate', 'Event end date'),
  ColumnItem('rainCheckDate', 'Rain check date'),
  ColumnItem('isPublished', 'Is Published').setComponent('boolean'),
  ColumnItem('description', 'Description'),
])
  .showPrint(false);
