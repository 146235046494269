import React from 'react';
import { useSelector } from 'react-redux';
import { H2 } from '@allthingswww/client-act-shared';
import styled from 'styled-components';
import { getMyInfo } from '../../../redux/member';

const StyledHeading = styled(H2)`
margin: 2em 0;
`;

export default ({ children }) => {
  const member = useSelector(getMyInfo);
  const viewCount = member.viewCount || 0;

  return (
    <div>
      <StyledHeading>Home</StyledHeading>
      <p>
        Your profile has been viewed
        {' '}
        <span style={{ fontWeight: 'bold' }}>{viewCount}</span>
        {' '}
        times.
      </p>
      {children}
    </div>
  );
};
