/* eslint-disable no-restricted-syntax */
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { H2 } from '@allthingswww/client-act-shared';
import UploadClient from '@uploadcare/upload-client';
import _, { get } from 'lodash';

import Survey from '../../Survey';
import surveyData from '../../../../static/surveys/createEvent.json';
import { createEvent } from '../../../redux/event';
import { getEventsItems } from '../../../redux/eventsItems';
import { dataURLtoFile } from '../../../lib';

const Container = styled.div``;

function mergeDateAndTime(date, time) {
  return `${date}T${time}:00`;
}

export default () => {
  const dispatch = useDispatch();
  const allEventItems = useSelector(getEventsItems);
  const eventItemsIndex = surveyData.pages[0].elements.findIndex(
    (el) => el.name === 'eventItems',
  );

  const onSubmit = async (surveyResults) => {
    const { data } = surveyResults;

    if (data.time && data.date) {
      data.date = mergeDateAndTime(data.date, data.time);
      delete data.time;
    }

    if (data.endDate && data.endTime) {
      data.endDate = mergeDateAndTime(data.endDate, data.endTime);
      delete data.endTime;
    }

    if (data.rainCheckDate && data.rainCheckTime) {
      data.rainCheckDate = mergeDateAndTime(data.rainCheckDate, data.rainCheckTime);
      delete data.rainCheckTime;
    }

    if (data.maxDateOfRegistration && data.maxDateOfRegistrationTime) {
      data.maxDateOfRegistration = mergeDateAndTime(data.maxDateOfRegistration, data.maxDateOfRegistrationTime);
      delete data.maxDateOfRegistrationTime;
    }

    const { eventItems = [] } = data;
    if (!_.isEmpty(eventItems)) {
      data.eventItems = JSON.stringify(_.map(data.eventItems,
        (item) => _.filter(allEventItems, (i) => i.name === item)).flat());
    } else {
      data.eventItems = JSON.stringify(eventItems);
    }

    const { photoUrls = [] } = data;
    if (!_.isEmpty(photoUrls)) {
      const client = new UploadClient({
        publicKey: get(process, 'env.UPLOADCARE_PUBLIC_KEY', '5249d2e6dd73fb499ec3'),
      });

      for (let i = 0; i < photoUrls.length; i += 1) {
        const { content, name } = photoUrls[i];
        const fileToUpload = dataURLtoFile(content, name);
        // eslint-disable-next-line no-await-in-loop
        const upload = await client.uploadFile(fileToUpload);
        const { cdnUrl } = upload;
        photoUrls[i] = cdnUrl;
      }
    }

    const payload = {
      ...data,
      photoUrls: photoUrls.toString(),
      memberFee: data.memberFee * 100,
      nonMemberFee: data.nonMemberFee * 100,
    };
    dispatch(createEvent(payload));
  };

  const items = _.map(allEventItems, (value) => value.name).filter((value) => value !== null);

  surveyData.pages[0].elements[13].choices = items;

  return (
    <Container>
      <H2>Create an event</H2>
      <Survey
        model={surveyData}
        onSubmit={onSubmit}
        data={{}}
      />
    </Container>
  );
};
