/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import { H2, Button } from '@allthingswww/client-act-shared';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { getPendingMembers, getMembers, updateMembers } from '../../../redux/members';
import memberBuilder from './muiColumnBuilder';

const { fetchWAuth } = require('../../../lib');

export default ({ children }) => {
  const members = useSelector(getPendingMembers);
  useSelector(getMembers);
  const dispatch = useDispatch();

  const muiDataTableBuilder = memberBuilder()
    .setData(members)
    .getColumnItemByKey('fullName')
    .show().moveToEnd().build()

    .getColumnItemByKey('companyName')
    .show().moveToEnd().build()

    .getColumnItemByKey('phone')
    .show().moveToEnd().build()

    .getColumnItemByKey('email')
    .show().moveToEnd().build()

    .getColumnItemByKey('membershipLevel')
    .show().moveToEnd().build()

    .sortVisible()
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'single',
      sortOrder: { name: 'membershipLevel', direction: 'asc' },
    });

  const customToolBarRenderer = (selectedRows) => {
    const selectedItem = muiDataTableBuilder.findItemsBySelectionData(selectedRows)[0];

    const approvePendingUser = (id) => {
      dispatch(updateMembers({ id, data: {}, action: 'approveApplication' }));

      fetchWAuth('/.netlify/functions/send-mail', {
        body: JSON.stringify({
          subject: 'Your profile changes have been approved',
          to: selectedItem.email,
          text: 'Congratulations, Your profile changes have been approved.',
          html: '',
        }),
      });
    };

    const archiveMembers = (selectedItems = []) => {
      selectedItems.map(({ _id }) => dispatch(
        updateMembers({
          data: { isArchived: true },
          id: _id,
          action: 'memberEdit',
        }),
      ));
    };

    return (
      <div>
        <Button.Primary href={`/member/${selectedItem._id}`}>
          Details
        </Button.Primary>
        <Button.Primary href="" handleClick={() => archiveMembers([selectedItem])}>
              Archive Member
            </Button.Primary>
        {' '}
        <Button.Primary href={`/member-edit/${selectedItem._id}`}>
          Edit
        </Button.Primary>
        {' '}
        <Button.Primary
          href=""
          onClick={() => approvePendingUser(selectedItem._id)}
        >
          Approve
        </Button.Primary>
      </div>
    );
  };

  muiDataTableBuilder.applyOptions({ customToolbarSelect: customToolBarRenderer });

  const data = members;
  return (
    <div>
      <H2>
        Edits Pending Approval
      </H2>
      {children}
      <MUIDataTable
        title="Members"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}

      />
    </div>
  );
};
