import { ColumnBuilder, ColumnItem } from '../muiColumnBuilder';

export default () => ColumnBuilder([
  ColumnItem('_id', 'ID').filter(true),
  ColumnItem('name', 'Name').filter(true),
  ColumnItem('description', 'Description'),
  ColumnItem('minQty', 'Minimum Quantity'),
  ColumnItem('maxQty', 'Maximum Quantity'),
  ColumnItem('unitPrice', 'Unit Price (cents)'),
])
  .showPrint(false);
