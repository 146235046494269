/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../components/Layout';
import Login from '../components/MemberArea/Login';
import AuthenticatedHome from '../components/MemberArea/AuthenticatedHome';
import { getSessionUser, getAuthenticated } from '../redux/user';

const LoginPage = (props) => {
  const user = useSelector(getSessionUser);
  const authenticated = useSelector(getAuthenticated);

  if (!user || !authenticated) {
    return (
      <Layout title="Member Login" condensed subtitle="">
        <Login />
      </Layout>
    );
  }

  return (
    <Layout title="Members" subtitle="" condensed={!!props['*']}>
      <AuthenticatedHome user={user} />
    </Layout>
  );
};

export default LoginPage;
