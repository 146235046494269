import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Input, Button, H1, H2,
} from '@allthingswww/client-act-shared';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// import { renderEmail } from 'react-html-email';

import fetchPublic from '../../../lib/fetchPublic';
import { getMyInfo, updateMember } from '../../../redux/member';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: 500,
      height: '2rem',
      border: '2px solid #777',
      marginBottom: '2rem',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#929292',
      },
    },
    invalid: {
      iconColor: '#eb6565',
      color: '#eb6565',
    },
  },
};

const Div = styled.div`
  margin-bottom: 1rem;
`;

const CardField = ({ onChange }) => (
  <div>
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const ErrorMessage = ({ children }) => (
  <Div>
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#000"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="red"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </Div>
);

const FieldSet = styled.fieldset`
  margin-bottom: 2rem;
  border: 2px solid #777;
  line-height: 20px;
  padding: 12px 6px;
  margin-bottom: 2rem;
  margin-top: 0.75rem;
`;

const Flex = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const StyledH2 = styled(H2)`
  margin: 0.5rem 0rem;
`;

const Currency = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

const CheckoutForm = (props) => {
  const [selectedPlan] = useState(() => _.get(props, 'location.state.selectedPlan', null));
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [stringifiedPaymentMethod, setStringifiedPaymentMethod] = useState('');
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
  });
  const dispatch = useDispatch();
  const myInfo = useSelector(getMyInfo);

  useEffect(() => {
    if (paymentMethod) {
      setStringifiedPaymentMethod(JSON.stringify(paymentMethod));
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (stringifiedPaymentMethod !== '') {
      // eslint-disable-next-line no-use-before-define
      // sendReceiptByEmail({ destinationEmailAddress: paymentMethod.billing_details.email });
    }
  }, [stringifiedPaymentMethod]);

  if (!selectedPlan) {
    navigate('/members/choose-membership-plan');
  }

  if (myInfo.isMemberShipValid) {
    navigate('members/manage-subscriptions');
  }

  function onSubscriptionComplete(result) {
    // Payment was successful.
    if (result.subscription.updatedSub.plan.active) {
      setPaymentMethod(result);
      const data = { isMemberShipValid: true };
      // eslint-disable-next-line no-underscore-dangle
      dispatch(updateMember({ data, id: myInfo._id, action: 'myinfo' }));
    }
  }

  function updateSubscription({ customerId, paymentMethodId, priceId }) {
    return (
      fetch('/.netlify/functions/update-subscription', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId,
          priceId,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            throw result;
          }
          return result;
        })
        .then((result) => ({
          paymentMethodId,
          priceId,
          subscription: result,
        }))
        .then(onSubscriptionComplete)
        .catch((e) => {
          setError(e);
        })
    );
  }

  function createPaymentMethod() {
    const customerId = myInfo.stripeID;
    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      })
      .then((result) => {
        if (result.error) {
          setError(result);
        } else {
          updateSubscription({
            customerId,
            paymentMethodId: result.paymentMethod.id,
            priceId: selectedPlan.price_id,
          });
        }
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    createPaymentMethod();
  }

  return paymentMethod ? (
    <div>
      <H1>
        Subscription activated!
      </H1>
    </div>
  ) : (
    <div>
      <StyledH2>Enter your card details</StyledH2>
      <StyledH2>Your subscription will start now</StyledH2>
      <Div>
        Total due now
        {' '}
        {Currency.format((selectedPlan && (selectedPlan.price / 100)))}
      </Div>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <Input
            label="Name"
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            handleChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value });
            }}
          />
          <Input
            label="Email"
            id="email"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            handleChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value });
            }}
          />
          <Input
            label="Phone"
            id="phone"
            type="tel"
            placeholder="(941) 555-0123"
            required
            autoComplete="tel"
            value={billingDetails.phone}
            handleChange={(e) => {
              setBillingDetails({ ...billingDetails, phone: e.target.value });
            }}
          />
        </fieldset>
        <FieldSet>
          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </FieldSet>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <Flex>
          <Button.Button variant href="" onClick={() => navigate(-1)}>Back</Button.Button>
          <Button.Primary disabled={!stripe || error}>
            {processing && (<span>processing...</span>)}
            {(!processing) && <span>Pay</span>}
          </Button.Primary>
        </Flex>
      </form>
    </div>

  );
};

export default CheckoutForm;
