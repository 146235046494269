/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import React from 'react';
import styled from 'styled-components';
import MUIDataTable from 'mui-datatables';
import { H2 } from '@allthingswww/client-act-shared';
import { useSelector, useDispatch } from 'react-redux';
import {
  getMembers,
  getAllSpecialities,
  updateMembers,
} from '../../../redux/members';
import { getMyInfo } from '../../../redux/member';

const AddCatContainer = styled.div`
  margin: 20px 0;
`;

const CatInput = styled.input`
  padding: 8px;
`;
const AddBtn = styled.button`
  border: 1px solid;
  padding: 8px 15px;
  margin-left: 10px;
  cursor: pointer;
`;

export default ({ children }) => {
  const dispatch = useDispatch();
  const members = useSelector(getMembers);
  const member = useSelector(getMyInfo);
  const specialities = useSelector(getAllSpecialities).filter(
    (spec) => spec.label !== '',
  );
  const data = specialities.map((spec) => [spec.label]);

  const columns = ['Categories'];
  const options = {
    filterType: 'checkbox',
    onRowsDelete: (rowsDeleted) => handleCatDelete(rowsDeleted),
  };

  const addCategory = () => {
    // This will add new category to the currently logged in user which must be an admin
    const newCat = document.querySelector('#new_category');
    const id = member._id;
    // eslint-disable-next-line no-shadow
    const data = {
      companySpecialities: member.companySpecialities.concat(
        `${newCat.value.trim().toLowerCase()},`,
      ),
    };
    dispatch(updateMembers({ data, id, action: 'memberEdit' }));
    newCat.value = '';
  };

  const handleCatDelete = (rowsDeleted) => {
    const CategoriesToDelete = rowsDeleted.data.map(
      (d) => data[d.dataIndex][0],
    );
    members.map((m) => {
      if (m.companySpecialities !== null) {
        const newMember = {
          ...m,
          companySpecialities: m.companySpecialities
            .split(',')
            .filter(Boolean),
        };

        CategoriesToDelete.map((cat) => {
          if (newMember.companySpecialities.indexOf(cat) > -1) {
            // eslint-disable-next-line no-shadow
            const data = {
              companySpecialities: newMember.companySpecialities
                .filter((item) => item !== cat)
                .join(','),
            };

            if (data.companySpecialities !== '') {
              data.companySpecialities += ',';
            }
            dispatch(updateMembers({ data, id: m._id, action: 'memberEdit' }));
          }
        });
      }
    });
  };

  return (
    <div>
      <H2>Professions page</H2>
      {children}
      <AddCatContainer>
        <CatInput
          id="new_category"
          type="text"
          placeholder="Type new category"
        />
        <AddBtn variant="contained" onClick={addCategory}>
          Add Category
        </AddBtn>
      </AddCatContainer>
      <MUIDataTable
        title="Professions"
        data={data}
        columns={columns}
        options={options}
        onRowsDelete={handleCatDelete}
      />
    </div>
  );
};
