import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { H2 } from '@allthingswww/client-act-shared';
import { navigate } from '@reach/router';

import Survey from '../../Survey';
import surveyData from '../../../../static/surveys/memberInfo.json';
import { getMyInfo, updateMember } from '../../../redux/member';
import compressData from '../../../lib/compressData';
import expandMemberData from '../../../lib/expandMemberData';

const Container = styled.div``;

export default () => {
  const member = useSelector(getMyInfo);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-underscore-dangle
  const id = member._id;

  const onSubmit = (surveyResults) => {
    const data = compressData(surveyResults.data);
    dispatch(updateMember({ data, id, action: 'myinfo' }));
    navigate('/members');
  };

  const expandedMemberData = expandMemberData(member);

  return (
    <Container>
      <H2>My Info</H2>
      <Survey
        model={surveyData}
        onSubmit={onSubmit}
        data={expandedMemberData}
      />
    </Container>
  );
};
