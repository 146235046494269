/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import styled from 'styled-components';
import { H2, Button } from '@allthingswww/client-act-shared';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { getMembersOnly, updateMembers } from '../../../redux/members';
import memberBuilder from './muiColumnBuilder';

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-around;
`;

export default ({ children }) => {
  const members = useSelector(getMembersOnly);
  const dispatch = useDispatch();

  const muiDataTableBuilder = memberBuilder()
    .setData(members)
    .getColumnItemByKey('fullName')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('companyName')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('phone')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('email')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('adminReviewed')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('emergency')
    .show()
    .moveToEnd()
    .build()

    .sortVisible()
    .showDownload(true)
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'multiple',
    });

  const markMemberAsEmergency = (selectedItems = []) => {
    selectedItems.map(({ _id }) => dispatch(
      updateMembers({
        data: { emergency: true },
        id: _id,
        action: 'memberEdit',
      }),
    ));
  };

  const markMemberAsNotEmergency = (selectedItems = []) => {
    selectedItems.map(({ _id }) => dispatch(
      updateMembers({
        data: { emergency: false },
        id: _id,
        action: 'memberEdit',
      }),
    ));
  };

  const archiveMembers = (selectedItems = []) => {
    selectedItems.map(({ _id }) => dispatch(
      updateMembers({
        data: { isArchived: true },
        id: _id,
        action: 'memberEdit',
      }),
    ));
  };

  // const suspendMember = (selectedItems = []) => {
  //   selectedItems.map(({ _id }) => dispatch(
  //     updateMembers({
  //       data: {
  //         role: {
  //           create: {
  //             name: 'isSuspended',
  //             description: 'is suspended',
  //             isGuest: false,
  //             isPending: false,
  //             isAdmin: false,
  //             isMember: false,
  //             isSuperAdmin: false,
  //             isSuspended: true,
  //           },
  //         },
  //       },
  //       id: _id,
  //     }),
  //   ));
  // };

  // const openLinkInNewTab = (selectedItems = []) => {
  //   window.open(`/member/${selectedItems[0]._id}`);
  // };
  // const openEditLinkInNewTab = (selectedItems = []) => {
  //   window.open(`/member-edit/${selectedItems[0]._id}`);
  // };

  const customToolBarRenderer = (selectedRows) => {
    const selectedItems = muiDataTableBuilder.findItemsBySelectionData(
      selectedRows,
    );
    // console.log(selectedItems);

    return (
      <ActionButtons>
        {selectedItems.length === 1 && (
          <>
            <Button.Primary href={`/member/${selectedItems[0]._id}`}>
              Details
            </Button.Primary>
            <Button.Primary href="" handleClick={() => archiveMembers(selectedItems)}>
              Archive Member(s)
            </Button.Primary>
            {
            // <Button.Primary href={`/member-edit/${selectedItems[0]._id}`}>
            //   Edit
            // </Button.Primary>
            }
            {selectedItems[0].emergency ? (
              <Button.Primary
                href=""
                handleClick={() => markMemberAsNotEmergency(selectedItems)}
              >
                Unset Emergency
              </Button.Primary>
            ) : (
              <Button.Primary
                href=""
                handleClick={() => markMemberAsEmergency(selectedItems)}
              >
                Set Emergency
              </Button.Primary>
            )}
          </>
        )}
        {selectedItems.length > 1 && (
          <>
            <Button.Primary
              href=""
              handleClick={() => markMemberAsEmergency(selectedItems)}
            >
              Set Emergency
            </Button.Primary>
            <Button.Primary
              href=""
              handleClick={() => markMemberAsNotEmergency(selectedItems)}
            >
              Unset Emergency
            </Button.Primary>
            <Button.Primary href="" handleClick={() => archiveMembers(selectedItems)}>
              Archive Member(s)
            </Button.Primary>
          </>
        )}
        {/* <Button.Secondary
          href=""
          handleClick={() => suspendMember(selectedItems)}
        >
          Suspend
        </Button.Secondary> */}
      </ActionButtons>
    );
  };

  muiDataTableBuilder.applyOptions({
    customToolbarSelect: customToolBarRenderer,
  });

  const data = members;
  return (
    <div>
      <H2>Members</H2>
      {children}
      <MUIDataTable
        title="Members"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}
      />
    </div>
  );
};
