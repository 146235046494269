import React from 'react';
import { H2 } from '@allthingswww/client-act-shared';

import SendMessageForm from '../../SendMessageToMember';

export default () => (
  <div>
    <H2>Send Message</H2>
    <SendMessageForm />
  </div>
);
