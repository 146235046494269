/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';

import { H2, Button } from '@allthingswww/client-act-shared';
import MUIDataTable from 'mui-datatables';
import { getMyInfo, updateMember } from '../../../redux/member';
import subscriptionsBuilder from './Builders/subscriptionsBuilder';

const Currency = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
});

function timeToHuman(unixtime) {
  const theDate = new Date(unixtime * 1000);
  const dateString = theDate.toGMTString();
  return dateString;
}

export default ({ children }) => {
  const dispatch = useDispatch();
  const myInfo = useSelector(getMyInfo);
  const [subs, setSubs] = useState([]);
  const [error, setError] = useState([]);
  const [formattedSubsArr, setformattedSubsArr] = useState([]);

  useEffect(() => {
    if (subs.subscriptions) {
      const formattedSubs = subs && subs.subscriptions.map(
        ({ plan, current_period_start: currentPeriodStart, id }) => ({
          id,
          planName: `Member ${plan.interval}ly`,
          recurringPrice: `${Currency.format(plan.amount / 100)}/${plan.interval}`,
          timeofEnrollment: timeToHuman(currentPeriodStart),
        }),
      );
      setformattedSubsArr(formattedSubs);
    }
  }, [subs]);

  useEffect(() => {
    if (!_.isEmpty(error)) {
      alert('An error occured try again');
    }
  }, [error]);

  useEffect(() => {
    if (myInfo.stripeID) {
      fetch('/.netlify/functions/list-customer-subscriptions', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: myInfo.stripeID,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            throw result;
          }
          setSubs(result);
        })
        .catch((e) => {
          alert(e);
          setError(e);
        });
    }
  }, []);

  const muiDataTableBuilder = subscriptionsBuilder()
    .setData(formattedSubsArr)
    .getColumnItemByKey('planName')
    .show().moveToEnd().build()

    .getColumnItemByKey('recurringPrice')
    .show().moveToEnd().build()

    .getColumnItemByKey('timeofEnrollment')
    .show().moveToEnd().build()

    .sortVisible()
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'single',
      sortOrder: { name: 'isMemberShipValid', direction: 'asc' },
    });

  const customToolBarRenderer = (selectedRows) => {
    const selectedItem = muiDataTableBuilder.findItemsBySelectionData(selectedRows)[0];
    function onCancelSubscriptionComplete(result) {
      if (result) {
        const data = { isMemberShipValid: false };
        // eslint-disable-next-line no-underscore-dangle
        dispatch(updateMember({ data, id: myInfo._id, action: 'myinfo' }));
        alert('Successfully cancelled plan');
        navigate('/members/choose-membership-plan');
        return result;
      }
      return result;
    }

    const cancelSubscription = (id) => {
      fetch('/.netlify/functions/cancel-subscription', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          subId: id,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            throw result;
          }
          return result;
        })
        .then(onCancelSubscriptionComplete)
        .catch((e) => {
          setError(e);
        });
    };

    return (
      <div>
        <Button.Secondary href="" handleClick={() => cancelSubscription(selectedItem.id)}>
          Cancel
        </Button.Secondary>
      </div>
    );
  };

  muiDataTableBuilder.applyOptions({ customToolbarSelect: customToolBarRenderer });

  return (
    <div>
      <H2>
        Manage subscriptions
      </H2>
      {children}
      <MUIDataTable
        title="Subscriptions"
        data={formattedSubsArr}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}
      />
    </div>
  );
};
