import React from 'react';
import styled from 'styled-components';
import { Button } from '@allthingswww/client-act-shared';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Grow = styled.div`
  flex-grow: 0.9;
  font-size: 24px;
  color: #0f0f3a;
  font-weight: bold;
`;

const Container = styled.div`
  margin-bottom: 10px;
  padding-top: 10px;
`;

const Header = ({ name, logout, refresh }) => (
  <Container>
    <Flex>
      <Grow>
        Welcome,
        {' '}
        {name}
      </Grow>
      <Button.Button handleClick={refresh}>Refresh</Button.Button>
      <Button.Secondary href="" handleClick={logout}>Logout</Button.Secondary>
    </Flex>
  </Container>
);

export default Header;
