/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import {
  H2, Button, ErrorBoundary,
} from '@allthingswww/client-act-shared';
import { Widget as UploadCareWidget } from '@uploadcare/react-widget';
import _, { get } from 'lodash';

import { getMyInfo, updateMember } from '../../../redux/member';
import compressData from '../../../lib/compressData';
import ImageContainer from './ImageContainer';

/* TODO: [ACT-229] Remove Key and set as an environment variable */
const uploadCarePublicKey = get(process, 'env.UPLOADCARE_PUBLIC_KEY', '5249d2e6dd73fb499ec3');

const Container = styled.div`
  margin: 1rem;
  display: flex;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const UploadCareButtonContainer = styled.div`
  margin: 0 15px 0 0;
  display: inline-block;
  flex-grow: 1;
`;

const PreviewImage = styled.img`
  width: 150px;
  margin: 25px;
`;

export default () => {
  const menuItems = [
    {
      text: 'Open',
      onClick: (item) => {
        if (window !== undefined) {
          window.location.href = item;
        }
      },
    },
    {
      text: 'Delete',
      onClick: (item) => {
        const indexOfItem = sessionImages.indexOf(item);
        const images = sessionImages.filter(
          (image, index) => index !== indexOfItem,
        );
        const data = compressData({ images });
        setImage('');
        setSessionImages(images);
        dispatch(updateMember({ data, id, action: 'memberImages' }));
      },
    },
  ];
  const member = useSelector(getMyInfo);
  const memberImages = _.get(member, 'images', []);

  const [sessionImages, setSessionImages] = useState(memberImages);
  // const expandedMemberData = expandMemberData(member);
  const [image, setImage] = useState('');
  const dispatch = useDispatch();

  // eslint-disable-next-line no-underscore-dangle
  const id = member._id;
  const onSubmit = () => {
    const images = [...memberImages, image];
    const data = compressData({ ...member, images });
    setImage('');

    setSessionImages(images);
    dispatch(updateMember({ data, id, action: 'memberEdit' }));
    navigate('/members');
  };

  return (
    <div>
      <H2>Active Images</H2>
      <MainContainer>
        {sessionImages.map((item) => {
          const uploadCareLinkSplit = item.split('/');
          const imageId = uploadCareLinkSplit[uploadCareLinkSplit.length - 2];
          const imageLinkAndNumberOfImages = imageId.split('~');
          const isMultipleImagesLink = imageLinkAndNumberOfImages.length >= 1;
          if (isMultipleImagesLink) {
            const allSubImagesToDisplay = [];
            for (let i = 0; i < imageLinkAndNumberOfImages[1]; i++) {
              allSubImagesToDisplay.push(`https://ucarecdn.com/${imageId}/nth/${i}/`);
            }

            return allSubImagesToDisplay.map((subImage) => (
              <ImageContainer menuItems={menuItems} myItem={subImage}>
                {' '}
                <a href={subImage} target="_blank" rel="noreferrer">
                  <PreviewImage key={subImage} src={subImage} alt="Active Item" />
                </a>
              </ImageContainer>
            ));
          }
          return (
            <ImageContainer menuItems={menuItems} myItem={item}>
              {' '}
              <a href={item} target="_blank" rel="noreferrer">
                <PreviewImage key={item} src={item} alt="Active Item" />
              </a>
            </ImageContainer>
          );
        })}
      </MainContainer>

      <Container>
        <ErrorBoundary>
          <UploadCareButtonContainer>
            <p>Add Attachment</p>
            <UploadCareWidget
              effects="crop,rotate"
              publicKey={uploadCarePublicKey}
              onChange={(fileInfo) => setImage(fileInfo.cdnUrl)}
              previewStep="true"
              multipleMax="3"
              imagesOnly
              multiple
            />
          </UploadCareButtonContainer>
        </ErrorBoundary>
        <Button.Button isDisabled={image === ''} handleClick={onSubmit}>Save Changes</Button.Button>
      </Container>
    </div>
  );
};
