import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Input,
  TextArea,
  Button as DefaultButton,
  DropDown,
} from '@allthingswww/client-act-shared';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import fetchPublic from '../lib/fetchPublic';

const StyledDiv = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-flow: column nowrap;
`;

const Respect = styled.div`
  margin-top: 0.75rem;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 300;
  margin-left: 3px;
`;
const StyledMainContainer = styled.div`
  span {
    text-decoration: underline;
  }
`;
const HiddenInput = styled(Input)`
  display: none;
`;

const SendMessageToMember = () => {
  const member = useSelector((state) => state.member.member);
  const emails = [
    {
      value: 'info@actaz.net',
      name: 'info@actaz.net',
    },
  ];

  const [contactFormData, setContactFormData] = useState({
    fullName: member.fullName,
    email: '',
    subject: `Message from Act User - ${member.fullName}, ${member.companyName}`,
    message: '',
  });
  const [areAllRequiredFieldsFilled, setAreAllRequiredFieldsFilled] = useState(
    false,
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      email: toEmail, subject, message, fullName,
    } = contactFormData;

    const response = await fetchPublic('/.netlify/functions/send-mail', {
      body: JSON.stringify({
        to: toEmail,
        subject,
        text: `Message from ${fullName}
          ${message}
          `,
        html: '',
      }),
    });
    if (response) {
      if (typeof window !== 'undefined')alert('Message sent!');
    }
  };

  function handleChange({ target }) {
    const { value, name } = target;
    setContactFormData({
      ...contactFormData,
      [name]: value,
    });
  }

  useEffect(() => {
    if (
      contactFormData.email.length === 0
      || contactFormData.fullName.length === 0
      || contactFormData.subject.length === 0
      || contactFormData.message.length === 0
    ) {
      setAreAllRequiredFieldsFilled(false);
    } else {
      setAreAllRequiredFieldsFilled(true);
    }
  }, [contactFormData]);

  return (
    <StyledMainContainer>
      <form onSubmit={onSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <h2>{contactFormData.fullName}</h2>
        {/* <Input
          value={contactFormData.email}
          handleChange={handleChange}
          type="email"
          name="email"
          placeholder="Receiver Email address*"
          isRequired
        /> */}
        <DropDown
          options={emails}
          name="email"
          value={contactFormData.email}
          onChange={handleChange}
          placeholder="Select Email..."
        />
        <HiddenInput
          value={contactFormData.subject}
          handleChange={() => {}}
          type="text"
          name="subject"
          isRequired
        />

        <TextArea
          value={contactFormData.message}
          handleChange={handleChange}
          name="message"
          maxLength={5000}
          placeholder="Your message*"
        />
        <StyledDiv>
          <DefaultButton.Button
            variant
            type="submit"
            isDisabled={!areAllRequiredFieldsFilled}
          >
            Send Message
          </DefaultButton.Button>
          <Respect>
            <Link to="/privacy">
              <span>We respect your privacy</span>
            </Link>
          </Respect>
        </StyledDiv>
      </form>
    </StyledMainContainer>
  );
};

export default SendMessageToMember;
