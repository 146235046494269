import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledContextMenu = styled.div`
    position: fixed;
    border: 1px solid black;
    background-color: white;
`;

const ContextMenuItem = styled.div`
    padding: 4px;
    &:hover {
        background-color: lightblue;
    cursor: pointer;
  }
`;

const ContextMenu = ({ parentRef, items, myItem }) => {
  const [isVisible, setVisibility] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const parent = parentRef.current;
    if (!parent) {
      return;
    }

    const showMenu = (event) => {
      event.preventDefault();

      setVisibility(true);
      setX(event.clientX);
      setY(event.clientY);
    };

    const closeMenu = () => {
      setVisibility(false);
    };

    parent.addEventListener('contextmenu', showMenu);
    window.addEventListener('click', closeMenu);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      parent.removeEventListener('contextmenu', showMenu);
      window.removeEventListener('click', closeMenu);
    };
  });

  const style = {
    top: y,
    left: x,
  };

  return isVisible ? (
    <StyledContextMenu style={style}>
      {items.map((item, index) => (
        <ContextMenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={() => item.onClick(myItem)}
        >
          {item.text}
        </ContextMenuItem>
      ))}
    </StyledContextMenu>
  ) : null;
};

export default ContextMenu;
