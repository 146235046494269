import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { H2 } from '@allthingswww/client-act-shared';
import { navigate } from '@reach/router';
import Survey from '../../Survey';
import surveyData from '../../../../static/surveys/businessProfile.json';
import { getMyInfo, updateMember } from '../../../redux/member';
import compressData from '../../../lib/compressData';
import expandMemberData from '../../../lib/expandMemberData';
import { getAllSpecialities } from '../../../redux/members';
import { transformSpecialitiesStringToID } from '../../../../functions/utils/occupations';
import { transformSpecialityIntoLabel, transformSpecialities } from '../../../lib/companySpecialities';

const { fetchWAuth } = require('../../../lib');

const Container = styled.div``;

export default () => {
  const member = useSelector(getMyInfo);
  const allSpecialities = useSelector(getAllSpecialities);
  const cleanedUpSpecialities = allSpecialities.filter((sp) => sp.label !== '');
  const dispatch = useDispatch();
  // eslint-disable-next-line no-underscore-dangle
  const id = member._id;
  const formattedMember = {
    ...member,
    companySpecialities: transformSpecialityIntoLabel(member),
  };

  const onSubmit = (surveyResults) => {
    const data = compressData(surveyResults.data);
    let finalCompanySpecialities = '';
    data.companySpecialities.forEach((speciality) => {
      finalCompanySpecialities += `${transformSpecialitiesStringToID(
        speciality,
      )},`;
    });

    if (data.otherSpecialities) {
      data.otherSpecialities.split(',').forEach((speciality) => {
        if (speciality !== '') {
          finalCompanySpecialities += `${transformSpecialitiesStringToID(
            speciality,
          )},`;
        }
      });
    }

    data.companySpecialities = finalCompanySpecialities;
    delete data.otherSpecialities;
    dispatch(updateMember({ data, id, action: 'memberEdit' }));
    navigate('/members');
    fetchWAuth('/.netlify/functions/send-mail',
      {
        body: JSON.stringify({
          subject: 'New Pending Edits from ACT',
          text: `User ${member.fullName} from ${member.companyName} has changed profile details. Waiting for your review`,
          html: '',
        }),
      });
  };

  const expandedMemberData = expandMemberData(formattedMember);
  const labels = cleanedUpSpecialities.map((speciality) => transformSpecialities(speciality.label));
  surveyData.pages[0].elements[4].choices = labels;

  return (
    <Container>
      <H2>Business Profile</H2>
      <Survey
        model={surveyData}
        onSubmit={onSubmit}
        data={expandedMemberData}
      />
    </Container>
  );
};
