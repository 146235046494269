/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@allthingswww/client-act-shared';
import styled from 'styled-components';
import { getAuthenticated } from '../../redux/user';

import LoginCaptionBckgd from '../../images/LoginCaption.svg';
import {
  fetchMembers,
} from '../../redux/members';
import {
  fetchEvents,
} from '../../redux/event';
import {
  fetchEventsItems,
} from '../../redux/eventsItems';
import {
  fetchPurchases,
} from '../../redux/purchase';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

const Flex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 50vh;
  @media (max-width: 820px) {
    flex-flow: column nowrap;
  }
`;
const Left = styled.div`
  flex-basis: 50%;
  @media (max-width: 820px) {
    display: none;
  }
`;
const MessageBlock = styled.div`
  display: none;
  color: red;
  @media (max-width: 820px) {
    display: block;
    margin: 40px 0;
    font-weight: bold;
  }
`;
const Right = styled.div`
  flex-basis: 50%;
`;
const Image = styled.img`
  position: absolute;
  height: 50vh;
  margin-top: -7rem;

  @media (max-width: 820px) {
    display: none;
  }
`;

const Spacer = styled.div`
  margin: 2rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: 820px) {
    margin: 1rem 0rem;
  }
`;

const Para = styled.p`
  line-height: 2;
`;

const Author = styled.p`
  text-align: right;
  font-size: 1.5rem;
  font-weight: bold;
`;

const Login = () => {
  const dispatch = useDispatch();
  const signUp = () => netlifyIdentity.open('signup');
  const login = () => netlifyIdentity.open('login');
  const isUserLoggedIn = useSelector(getAuthenticated);
  const showSignup = !isUserLoggedIn;

  const refresh = () => {
    dispatch(fetchMembers());
    dispatch(fetchEvents());
    dispatch(fetchEventsItems());
    dispatch(fetchPurchases());
  };

  useEffect(() => {
    refresh();
  }, [isUserLoggedIn]);

  return (
    <Flex>
      <MessageBlock>You can only login from desktop.</MessageBlock>
      <Left>
        <Spacer>
          <FlexColumn>
            <div>
              <Spacer>
                <Para>Already a member?</Para>
                <Button.Button handleClick={login}>Login</Button.Button>
              </Spacer>
            </div>
            {showSignup && (
              <div>
                <Spacer>
                  <Para>Not a member?</Para>
                  <Button.Button variant handleClick={signUp}>
                    Signup
                  </Button.Button>
                </Spacer>
              </div>
            )}
          </FlexColumn>
        </Spacer>
      </Left>
      <Right>
        <Image src={LoginCaptionBckgd} alt="Login Caption Background" />
        <Spacer>
          <Para>
            ACT's mission is to support, promote and advocate for the construction trades of Southern Arizona.
          </Para>
        </Spacer>
        {/* <Author>Act's mission</Author> */}
      </Right>
    </Flex>
  );
};

export default Login;
