/* eslint-disable */
import React, { useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Router } from '@reach/router';
import _ from 'lodash';
import { navigate } from 'gatsby';

import Header from './components/Header';
import MemberNav from './components/Nav';
import StepOne from '../SignUp/StepOne';
import PendingApproval from '../SignUp/PendingApproval';
import { getSessionUser, getAuthenticated, triggerLogout } from '../../redux/user';
import {
  getMyInfo,
  isGuest,
  isPending,
  isMember as isMemberSelector,
  isAdmin,
  isSuperAdmin,
  getIsFetchingMyInfo,
  fetchMyInfo,
  getIsActive,
} from '../../redux/member';
import { getMemberFullScreen } from '../../redux/app';
import {
  getIsFetchingMembers,
  getIsUpdatingMembersData,
  fetchMembers,
} from '../../redux/members';
import {
fetchEvents
} from '../../redux/event';
import {
fetchEventsItems
} from '../../redux/eventsItems';
import {
fetchPurchases
} from '../../redux/purchase'

import MyInfo from './MemberPages/MyInfo';
import AdminMembers from './MemberPages/AdminMembers';
import AdminNewApplications from './MemberPages/AdminNewApplications';
import AdminPendingMembers from './MemberPages/AdminPendingMembers';
import BusinessInfo from './MemberPages/BusinessInfo';
import MemberHome from './MemberPages/MemberHome';
import MemberImages from './MemberPages/MemberImages';
import MemberSendMessage from './MemberPages/MemberSendMessage';
import SiteAdmin from './MemberPages/SiteAdmin';
import SuperAdmin from './MemberPages/SuperAdmin';
import Professions from './MemberPages/Professions';
import CreateEvent from './MemberPages/AdminCreateEvent';
import ViewAllEvents from './MemberPages/AdminEvents';
import CreateEventItem from './MemberPages/AdminCreateEventItem';
import ViewAllEventItems from './MemberPages/AdminEventItems';
import ChoosePurchasePlan from '../SignUp/PurchasePlanForm';
import MembershipCheckout from './MemberPages/MembershipCheckout';
import ManageSubscriptions from './MemberPages/ManageSubscriptions';
import AdminArchivedMembers from './MemberPages/AdminArchivedMembers';

import { fetchWAuth, hasRole, memberLevelCheckBuilder, roles } from '../../lib';
import MyPurchases from './MemberPages/MyPurchases';

const NotFound = () => <p>Sorry, nothing here</p>;

const Step1 = StepOne;
const Pending = PendingApproval;

const { FREE, MEMBER, ADMIN, SUPERADMIN } = roles;

const Flex = styled.div`
  display: flex;
  & > div {
    margin: 15px 0;
  }
  & > div + div {
    margin-left: 30px;
  }
`;

const MainContent = styled.div`
  border: 1px solid #0f0f3a33;
  padding: 15px;
  flex-grow: 1;
  max-width: 840px;
  ${(props) => {
    if (props.fullScreen) {
      return `
      position: fixed;
      background: white;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 95vw;
      `;
    }
  }}
`;

const AuthenticatedHome = () => {
  const dispatch = useDispatch();
  const isFetchingMembers = useSelector(getIsFetchingMembers);
  const isFetchingMyInfo = useSelector(getIsFetchingMyInfo);
  const isUpdatingMembersData = useSelector(getIsUpdatingMembersData);
  const authenticated = useSelector(getAuthenticated);
  const user = useSelector(getSessionUser);
  const { roles } = user.app_metadata || {};
  const member = useSelector(getMyInfo);
  const guestUser = useSelector(isGuest);
  const pendingUser = useSelector(isPending);
  const memberUser = useSelector(isMemberSelector);
  const adminUser = useSelector(isAdmin);
  const superAdminUser = useSelector(isSuperAdmin);
  const isActive = useSelector(getIsActive)
  const fullScreen = useSelector(getMemberFullScreen);

  const refresh = () => {
    dispatch(fetchMembers());
    dispatch(fetchMyInfo());
    dispatch(fetchEvents());
    dispatch(fetchEventsItems());
    dispatch(fetchPurchases());
  };

  useEffect(() => {
    refresh();
  }, [authenticated]);

  // useEffect(() => {
  //   if (isActive === null && (member.role.isAdmin !==  true)) {
  //     navigate('/members/choose-membership-plan');
  //   }
  // }, [isActive]);

  if (!authenticated) {
    dispatch(triggerLogout());
    return null;
  }

  if (!member) {
    dispatch(triggerLogout());
    return null;
  }

  const { membershipLevel, adminReviewed } = member || {};
  const { isInitial, isNew, isMember } = memberLevelCheckBuilder(
    membershipLevel
  );
  const checkRole = hasRole(roles);
  
  const reviewedRoutes = !adminReviewed && (<Pending path="/pending" />);
  
  const newSignupRoutes = (guestUser || pendingUser) && isNew() && (
    <>
      <Step1 path="/" />
      <Pending path="/pending" />
    </>
  );

  const pendingRoutes = pendingUser && <Pending path="/" />;

  const memberRoutes = memberUser && (
    <>
      <MemberHome path="/" user={user} />
      <MyInfo path="/my-info" user={user} />
      <BusinessInfo path="/business" user={user} />
      <MemberImages path="/images" user={user} />
      <MemberSendMessage path="/messages" user={user} />
      <MyPurchases path="/my-purchases" user={user} />
      <ChoosePurchasePlan path="/choose-membership-plan" user={user} />
      <MembershipCheckout path="/purchase-membership-checkout" />
      <ManageSubscriptions path="/manage-subscriptions" user={user} />
    </>
  );
  
  const adminRoutes = adminUser && (
    <>
      <MemberHome path="/" user={user} />
      <MyInfo path="/my-info" user={user} />
      <MemberSendMessage path="/messages" user={user} />
      <AdminPendingMembers path="/approvePending" user={user} />
      <AdminMembers path="/members" user={user} />
      <AdminArchivedMembers path="/archivedMembers" user={user} />
      <SiteAdmin path="/siteAdmin" user={user} />
      <Professions path="/professions" user={user} />
      <CreateEvent path="/createEvent" user={user} />
      <CreateEventItem path="/createEventItem" user={user} />
      <ViewAllEvents path="/events" user={user} />
      <ViewAllEventItems path="/eventItems" user={user} />
      <MyPurchases path="/my-purchases" user={user} />
    </>
  );

  const superAdminRoutes = superAdminUser && (
    <>
      <AdminNewApplications path="/approveApplication" user={user} />
      <SuperAdmin path="/superAdmin" user={user} />
    </>
  );

  return (
    <div>
      <Header
        name={user.user_metadata.full_name}
        logout={() => netlifyIdentity.logout()}
        refresh={refresh}
      />
      {isFetchingMyInfo && <h1>Loading...</h1>}
      {!isFetchingMyInfo && (
        <Flex>
          <div>
            <MemberNav memberData={user} roles={roles} />
          </div>
          <MainContent fullScreen={fullScreen}>
            {isFetchingMembers || isUpdatingMembersData ? (
              <h1>Loading...</h1>
            ) : (
              <Router basepath="/members">
                {newSignupRoutes}
                {pendingRoutes}
                {reviewedRoutes}
                {memberRoutes}
                {adminRoutes}
                {superAdminRoutes}
                {pendingUser}
                <NotFound default />
              </Router>
            )}
          </MainContent>
        </Flex>
      )}
    </div>
  );
};

export default AuthenticatedHome;
