/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import styled from 'styled-components';
import { H2, Button } from '@allthingswww/client-act-shared';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { getArchivedMembers, updateMembers } from '../../../redux/members';
import memberBuilder from './muiColumnBuilder';

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: space-around;
`;

export default ({ children }) => {
  const members = useSelector(getArchivedMembers);
  const dispatch = useDispatch();

  const muiDataTableBuilder = memberBuilder()
    .setData(members)
    .getColumnItemByKey('fullName')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('companyName')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('phone')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('email')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('adminReviewed')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('emergency')
    .show()
    .moveToEnd()
    .build()

    .sortVisible()
    .showDownload(true)
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'multiple',
    });

  const unArchiveMembers = (selectedItems = []) => {
    selectedItems.map(({ _id }) => dispatch(
      updateMembers({
        data: { isArchived: false },
        id: _id,
        action: 'memberEdit',
      }),
    ));
  };

  const customToolBarRenderer = (selectedRows) => {
    const selectedItems = muiDataTableBuilder.findItemsBySelectionData(
      selectedRows,
    );
    // console.log(selectedItems);

    return (
      <ActionButtons>
        {selectedItems.length >= 1 && (
          <>
            <Button.Primary href="" handleClick={() => unArchiveMembers(selectedItems)}>
              Unarchive Member(s)
            </Button.Primary>
          </>
        )}
      </ActionButtons>
    );
  };

  muiDataTableBuilder.applyOptions({
    customToolbarSelect: customToolBarRenderer,
  });

  const data = members;
  return (
    <div>
      <H2>Archived Members</H2>
      {children}
      <MUIDataTable
        title="Archived Members"
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}
      />
    </div>
  );
};
