import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { H2 } from '@allthingswww/client-act-shared';

const Center = styled.div`
  text-align: center;
`;

const PendingApproval = () => (
  <Center>
    <H2>Thank you for the application! Your profile is pending approval.</H2>
    <p>
      You’ll receive additional information after a staff member has approved
      your application
    </p>
  </Center>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(PendingApproval);
